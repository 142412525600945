import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import CarLeadForm, { CarLeadData } from '../../../components/car-lead-form';
import FlexCenter from '../../../components/flex-center';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: CarLeadData = {
  car: '',
  name: '',
  email: '',
  phone: '',
};

const noFollowMetaTag = [
  {
    name: 'robots',
    content: 'noindex,nofollow',
  }
];

const TruckInsuranceLandingAr: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "photo_landing_trucks_hero_mobile" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "photo_landing_trucks_hero_desktop" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);
  return (
    <Layout gtmId="GTM-N8SRQZL">
      <SEO
        title="Seguros para Camiones. Seguros para Camiones Precios"
        siteTitle="123seguro"
        description="En 123Seguro vas a poder encontrar los mejores Seguros para Camiones y contratar el indicado para vos. ¡Cotizá ahora Seguros de Camiones!"
        meta={noFollowMetaTag}
      />
      <Hero
        title="Seguros para camiones"
        subtitle="Tu seguro para camión\nestá en 123 ¡Cotizalo!"
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
      />
      <Container>
        <FlexCenter>
          <Column desktop={6}>
            {submitted ? (
              <SuccessNotice
                noticeText={`Recibimos tu solicitud de cotización para tu ${leadInfo.car}. Nos contactaremos con vos por WhatsApp (${leadInfo.phone}) o por mail (${leadInfo.email}).`}
                solicitudText="¡Solicitud enviada!"
                verifyText="¿No son esos tus datos?"
                onRequestChange={() => submit(false)}
                changeText="Modificarlos"
              />
            ) : (
              <CarLeadForm
                {...leadInfo}
                labelCar="MARCA, MODELO Y AÑO DE TU CAMIÓN"
                sampleCar="Iveco Hi-Way 2018"
                labelName="NOMBRE"
                sampleName="Juan Martinez"
                labelEmail="CORREO ELECTRÓNICO"
                sampleEmail="nombre@dominio.com"
                labelPhone="TELÉFONO CELULAR"
                samplePhone="Ej.: 35832132"
                minPhoneLength={8}
                maxPhoneLength={12}
                countryCode="ar"
                errorMessage="Por favor revisá los campos con error"
                missingCarErrorMessage="Ingresá tu vehículo; ej.: Iveco Hi-Way 2018"
                missingNameErrorMessage="Ingresá tu nombre; ej.: Juan Martinez"
                missingEmailErrorMessage="Ingresá tu email; ej.:nombre@dominio.com"
                invalidEmailErrorMessage="Ingresá un email válido; ej.: nombre@dominio.com"
                missingPhoneErrorMessage="Ingresá tu teléfono; ej.: 35832132"
                invalidPhoneErrorMessage="Ingresá un teléfono válido; ej.: 35832132"
                focusEmailMessage="Correo al que enviaremos tu póliza"
                focusPhoneMessage="Celular para la gestión de tu seguro"
                submitbutton="Cotizar seguros para camiones"
                vehicleType="truck"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              />
            )}
          </Column>
        </FlexCenter>
      </Container>
    </Layout>
  );
};

export default TruckInsuranceLandingAr;
